<template>
  <div class="flex">
    <div class="w-full">

      <form
        class="space-y-4"
        @submit.prevent="isUpdating ? update(bulk) : store(bulk).then(() => $router.back())"
        @keydown="removeError($event.target.id)">
        <h1 class="text-2xl">
          {{ isUpdating ? 'Actualizar' : 'Crear' }} horario
        </h1>
        <div class="flex flex-wrap -mx-2">
          <div class="w-full md:w-1/2 lg:w-1/2 px-2">
            <div class="form-group">
              <label for="classroom_id">Aula</label>
              <div class="flex flex-wrap w-full">
                <a-select
                  class="flex-grow"
                  @input="handle"
                  id="classroom_id"
                  :loading="loadingClassrooms"
                  :source="classrooms.map(it => ({ label: it.code, value: it.id }))"
                  :error="error('classroom_id', { attribute: 'aula de clase' })"
                  v-model="bulk.classroom_id" />
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col" v-if="bulk.classroom_id && bulk.course_section_id">
          <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
              <table class="min-w-full">
                <thead>
                <tr>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium uppercase tracking-wider">Hora</th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium uppercase tracking-wider" v-for="(day, k) in days" :key="k">
                    {{ day.label }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <template v-if="mappedClassHours.length === 0">
                  <tr>
                    <td class="text-center" :colspan="days.length + 1">
                      <p class="text-2xl p-4">{{emptyClasshoursText}}</p>
                      <a-button class="mb-4" outlined :to="{ name: 'create_classhour', query: { period: currentCourseSectionOnState.period_id } }">Crear hora de clase</a-button>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr v-for="(hour, t) in mappedClassHours" :key="hour.value">
                    <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                      {{ hour.label.split('-')[0].trim() | h24 }} / {{ hour.label.split('-')[1].trim() | h24 }}
                    </td>
                    <td
                      class="px-6 py-4 whitespaence-no-wrap border-b border-gray-200 text-center mx-auto"
                      :class="[isClasshourUnavailable(day.value, hour.value) && 'bg-gray-300']"
                      v-for="(day, k) in days" :key="k">
                      <input type="checkbox" class="appearance-none cursor-pointer border checked:ring-2 checked:ring-primary w-4 h-4 checked:border-transparent ring-offset-2 border rounded border-gray-200 active:scale-95 transition-all transform checked:bg-primary" :disabled="isClasshourUnavailable(day.value, hour.value)" :id="`course_input_${k}_${t}`" :value="hour.value" v-model="bulk.schedules[k].class_hours_ids" />
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="flex space-x-2 pb-4">
          <a-button mode="primary" type="submit" :loading="loading" :disabled="loading || loadingClasshours || loadingClassrooms">
            {{ isUpdating ? 'Actualizar cambios' : 'Guardar cambios' }}
          </a-button>

          <a-button outlined :to="{ name: 'schedules' }">
            Cancelar
          </a-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  data: () => ({
    bulk: {
      course_section_id: null,
      classroom_id: null,
      schedules: [
        { day: 1, class_hours_ids: [] },
        { day: 2, class_hours_ids: [] },
        { day: 3, class_hours_ids: [] },
        { day: 4, class_hours_ids: [] },
        { day: 5, class_hours_ids: [] },
      ]
    },
    isUpdating: false
  }),
  computed: {
    ...mapGetters({
      hasError: 'hasError',
      error: 'error'
    }),
    ...mapState({
      schedules: state => state.schedules.pagination.data,
      currentScheduleOnState: state => state.schedules.current,
      currentCourseSectionOnState: state => state.sections.courseSection,
      loading: state => state.schedules.loading,
      classrooms: state => state.classrooms.all,
      loadingClassrooms: state => state.classrooms.loading,
      classhours: state => state.classhours.all,
      loadingClasshours: state => state.classhours.loading,
      takenClasshours: state => state.classrooms.schedules
    }),
    days() {
      return [
        { label: 'Lunes', value: 1 },
        { label: 'Martes', value: 2 },
        { label: 'Miércoles', value: 3 },
        { label: 'Jueves', value: 4 },
        { label: 'Viernes', value: 5 }
      ]
    },
    mappedClassHours() {
      return this.classhours.map(hour => {
        const start_hour = this.$options.filters.timePadding(hour.start_hour)
        const start_minute = this.$options.filters.timePadding(hour.start_minute)
        const end_hour = this.$options.filters.timePadding(hour.end_hour)
        const end_minute = this.$options.filters.timePadding(hour.end_minute)
        return {
          label: `${start_hour}:${start_minute} - ${end_hour}:${end_minute}`,
          value: hour.id
        }
      })
    },
    unavailableDays() {

      let schedules = [];
      this.takenClasshours.forEach(it => {
        let foundIndex = schedules.findIndex(el => el.day == it.day);

        if (foundIndex < 0) {
          schedules.push({
            day: it.day,
            class_hours_ids: [it.class_hour_id]
          })
        } else {
          let hasClasshour = schedules[foundIndex].class_hours_ids.includes(it.class_hour_id)

          if (!hasClasshour) {
            schedules[foundIndex].class_hours_ids.push(it.class_hour_id)
          }
        }
      })

      return schedules
    },
    emptyClasshoursText() {
      let period = this.currentCourseSectionOnState?.period
      if (period) {
        return `No hay horas de clase para el período ${period.year}-${period.number}`
      }

      return `No hay horas de clase para el período actual`
    }
  },
  methods: {
    ...mapMutations({
      setNavbarTitle: 'SET_NAVBAR_TITLE',
      setClassrooms: 'classrooms/SET_CLASSHOURS'
    }),
    ...mapActions({
      fetchSchedule: 'schedules/fetch',
      store: 'schedules/bulkStore',
      update: 'schedules/update',
      removeError: 'removeError',
      fetchCourseSections: 'sections/fetchCourseSections',
      fetchClassrooms: 'classrooms/index',
      fetchClasshours: 'classhours/index',
      fetchClassroomSchedule: 'classrooms/schedules',
      fetchSection: 'sections/fetch'
    }),
    handle(id) {
      this.fetchClassroomSchedule({
        id,
        filters: {
          limit: 0
        }
      })

      this.bulk.schedules = [
        { "day": 1, "class_hours_ids": [] },
        { "day": 2, "class_hours_ids": [] },
        { "day": 3, "class_hours_ids": [] },
        { "day": 4, "class_hours_ids": [] },
        { "day": 5, "class_hours_ids": [] },
      ]
    },
    pushBulk() {
      this.bulk.schedules.push({
        day: null,
        class_hours_ids: []
      })
    },
    isDaySelectedForClassHour(index, hour) {
      return this.bulk.schedules[index].class_hours_ids.includes(hour)
    },
    translatedDay(day) {
      return this.days.find(it => it.value === day)?.label ?? ''
    },
    isClasshourUnavailable(day, classhour_id) {
      return this.unavailableDays.find(it => it.day === day)?.class_hours_ids?.includes(classhour_id)
    }
  },
  watch: {
    currentCourseSectionOnState(val) {
      this.bulk.course_section_id = val.id
    }
  },
  mounted() {

    this.fetchCourseSections({
      ...this.$route.params,
      filters: {
        with: 'schedules,period'
      },
    }).then(response => {
      this.fetchClasshours({ limit: 0, enabled: true, period: response.data.period_id })
    })

    this.fetchSection(this.$route.params.section_id)

    this.fetchClassrooms({ limit: 0, enabled: true })
  },
  destroyed() {
    this.setClassrooms([])
  }
}
</script>
