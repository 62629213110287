<template>
  <div id="studentsCoursesNotes" class="text-sm">
    <div class="flex flex-1/2 justify-between mb-3 text-lg mx-2">
        <p>Semestre: {{ carpetonData.semester_number }}</p>
        <p>Sección: {{ carpetonData.section_code }}</p>
      <!-- TODO <p> FECHA </p>-->
    </div>
    <div class="grid grid-cols-2 gap-2 xl:grid-cols-1">
      <div v-for="(student, key) in carpetonData.students" :key="key" >
        <student-card :student="student" />
      </div>
    </div>
  </div>
</template>

<script>
import studentCard from "./studentCard";

export default {
  name: 'studentsCoursesNotes',
  components: {
    studentCard
  },
  data() {
    return {
      carpetonData: []
    }
  },
  methods: {
    verCarpeton() {
      this.$repository.sections
        .studentCourses(this.$route.params.section_id)
        .then(({ data:response }) => {
          // this.carpetonData = JSON.parse(JSON.stringify(response))
          response.students.sort(function(a, b){
              if(a.student_last_name < b.student_last_name) { return -1; }
              if(a.student_last_name > b.student_last_name) { return 1; }
              return 0;
          })
          this.carpetonData = Object.assign([], response)
          
        })
        .catch(err => (console.log(err)))
    }
  },
  mounted() {
    this.verCarpeton()
  },
}
</script>

<style scoped>

</style>
