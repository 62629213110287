<template>
    <div>
        <h3 class="text-2xl my-4">Estudiantes</h3>
<!--        <a-laravel-pagination align="right" :data="pagination" @change="fetch({ section: $route.params.section_id, page: $event })" :disabled="loading" class="mt-3"/>-->
        <a-table bordered rounded :source="pagination.data" :columns="columns" :loading="loading" />
<!--        <a-laravel-pagination align="right" :data="pagination" @change="fetch({ section: $route.params.section_id, page: $event })" :disabled="loading" class="mt-3"/>-->
    </div>
</template>

<script>
export default {
    data: () => ({
        pagination: {
            data: []
        },
        columns: [
            { title: 'nombre', mutate: ({ key }) => key.last_name + ', ' + key.first_name },
            { title: 'cédula', key: 'id_document' },
            { title: 'teléfono', key: 'phone' },
            { title: 'sexo', key: 'gender', mutate: ({ key }) => key === 'male' ? 'Hombre' : 'Mujer' },
            { title: 'correo electrónico', key: 'email' },
            { title: 'correo institucional', key: 'institution_email' },
        ],
        loading: false,
    }),
    methods: {
        fetch(query) {
            this.loading = !this.loading
            this.$repository.students
                .index(query)
                .then((data) => {
                    // data.data.sort(function(a, b){
                    //     if(a.first_name < b.first_name) { return -1; }
                    //     if(a.first_name > b.first_name) { return 1; }
                    //     return 0;
                    // })
                    this.pagination = data
                }).finally(() => this.loading = !this.loading)
        },
    },
    mounted() {
        this.fetch({ section: this.$route.params.section_id, limit: 0, order_by: 'last_name,asc' })
    }
}
</script>
