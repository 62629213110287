<template>
  <a-card class="flex flex-col mx-2 mb-4 ">
    <div class="flex flex-col">
      <p><b>Nombre:</b> {{ `${student.student_last_name}, ${student.student_first_name}` }}</p>
      <p><b>CI:</b> {{ student.student_dni }}</p>
    </div>
    <div class="flex flex-col mt-2 rounded-md overflow-hidden">
      <div class="flex mb-2">
        <div class="flex-1"/>
        <div class="flex flex-1 text-center font-bold bg-purple-400 text-white">
          <p class="flex-1" title="Nota del 1er Corte">N1</p>
          <p class="flex-1" title="Nota del 2do Corte">N2</p>
          <p class="flex-1" title="Nota promedio de los 2 cortes">NP</p>
          <p class="flex-1" title="Nota de Entrega Final">EF</p>
          <p class="flex-1" title="Nota Total">NT</p>
          <p class="flex-1" title="Nota Final">NF</p>
        </div>
      </div>
      <div v-for="(course, key) in student.courses.sort((a, b) => a.course_id - b.course_id)" :key="key" class="flex" :class="key % 2 ? 'bg-white' : 'bg-gray-100'">
        <p
            class="flex-1 my-1 ml-2"
            :title="course.failed_by_final_delivery ? 'Reprobado por entrega final' : ''"
            :class="course.failed_by_final_delivery ? 'text-red-400' : 'text-black'">
          {{ course.course_name }}
        </p>
        <div class="flex flex-1 text-center">
          <p class="flex-1">{{ course.first_cut_note }}</p>
          <p class="flex-1">{{ course.second_cut_note }}</p>
          <p class="flex-1">{{ course.cut_average_note }}</p>
<!--          <p class="flex-1" :class="course.final_delivery_note_weighting_prc === 0 ? 'text-red-400 font-bold' : 'text-black'">{{ course.final_delivery_note_weighting_prc === 0 ? 'N/A' : course.failed_by_final_delivery ? 'N/E' : course.final_delivery_note }}</p>-->
          <p
              class="flex-1"
              :title="course.failed_by_final_delivery ? 'No entregó' : ''"
              :class="!compareCourse(course) || course.failed_by_final_delivery ? 'text-red-400' : 'text-black'">
            {{ course.failed_by_final_delivery ? 'N/E' : compareCourse(course) ? course.final_delivery_note : 'N/A' }}
          </p>
          <p class="flex-1">{{ course.final_note_raw }}</p>
<!--          <p class="flex-1 font-bold">{{ course.final_note }}</p>-->
          <p class="flex-1 font-bold">{{ course.overwritten_final_note || course.overwritten_final_note === 0 ? course.overwritten_final_note : course.final_note }}</p>
        </div>
      </div>
    </div>
  </a-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'studentCard',
  props: {
    student: { required: false, type: Object },
  },
  computed: {
    ...mapState({
      section: state => state.sections.section,
    }),
  },
  methods: {
    compareCourse(course) {
      let courseSection = this.section.courses.find((val) => val.id === course.course_id)
      return courseSection?.pivot?.final_delivery
    }
  }
}
</script>

<style scoped>

</style>
